@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");


.header__col{
  padding: 0 2%;
}

.text-white {
  --text-opacity: 1;
  color: #FFFFFF;
  color: rgba(255,255,255,var(--text-opacity));
}

.bg-blue {
  --bg-opacity: 1;
  background-color: rgb(251 , 191 , 36);
  background-color: rgba(251 , 191 , 36,var(--bg-opacity));
}

@media (min-width: 1100px){
  .prl-container--lg {
    padding-left: 2rem;
    padding-right: 2rem;
}
}


@media (min-width: 768px){
.prl-container--lg {
    margin: 0 auto;
    max-width: 1440px;
}
.prl-container--lg {
    max-width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
}}

@media (min-width: 1024px){
.lg\:block {
    display: block;
}
}

.region--secondary-nav {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 0;
}

@media (min-width: 1024px){
.inline-menu__item {
    display: inline;
}}

.inline-menu {
  font-family: "Source Sans Pro",sans-serif;
  font-weight: 600;
  font-size: .8rem;
  line-height: 1.3;
  list-style: none;
  margin: 0;
  padding: 0;
}



.inline-menu__item::after {
  content: 'I';
  color: #fff;
  font-weight: 300;
  margin: 0 0.45rem;
}

.inline-menu__item:nth-child(3)::after {
  display: none;
}

.ext{
  /* opacity: 0.9 !important; */
  
}

.ext:hover{
  text-shadow: .025rem 0 0 currentColor;
}

.ext2:hover  h2{
  text-shadow: .050rem 0 0 currentColor;
}

/* svg.ext {
  display: none !important;
}

svg {
  display: block;
  vertical-align: middle;
} */


.ext::after {
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='white'%3E%3Cpath d='M30.438 16.243c-.861 0-1.562.701-1.562 1.562v9.024a2.05 2.05 0 01-2.048 2.048H5.17a2.05 2.05 0 01-2.048-2.048V5.171A2.05 2.05 0 015.17 3.123h9.024c.861 0 1.562-.701 1.562-1.562s-.701-1.562-1.562-1.562H3.365A3.37 3.37 0 00-.001 3.365v25.267a3.37 3.37 0 003.366 3.366h25.267a3.37 3.37 0 003.366-3.366V17.803c0-.861-.701-1.562-1.562-1.562z'/%3E%3Cpath d='M30.438 0h-9.024c-.861 0-1.562.701-1.562 1.562s.701 1.562 1.562 1.562h5.264L9.782 20.017a1.549 1.549 0 000 2.202 1.549 1.549 0 002.202 0L28.877 5.326v5.264c0 .861.701 1.562 1.562 1.562s1.562-.701 1.562-1.562V1.563c0-.861-.701-1.562-1.562-1.562z'/%3E%3C/svg%3E");
  display: inline-block;
  height: 0.625rem;
  width: 0.625rem;
  background-size: cover;
  vertical-align: middle;
  margin-left: 0.3125rem;
}



body {
  overflow-x: hidden !important;
  font-family: Montserrat, sans-serif;
  box-sizing: border-box;
}

.bg-base-100,
input,
body {
  background-color: white !important;
}

.bg-base-200,
.btn {
  background-color: hsl(0, 0%, 95%) !important;
}

.leading-12 {
  line-height: 3rem;
}

.bg-development {
  background-image: url(../public/assets/development.jpeg);
}

.bg-essentials-platform {
  background-image: url(../public/assets/essentials_platform.jpeg);
}

.bg-real-estate {
  background-image: url(../public/assets/real_estate.jpeg);
}

.bg-strategic {
  background-image: url(../public/assets/strategic.jpeg);
}

.bg-house {
  background-image: url(../public/assets/building/house1.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 35rem;
}

.bg-career {
  background-image: url(../public/assets/building/house1.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 45rem;
}

@media (max-width: 700px) {
  .bg-career {
    margin-bottom: 68rem;
  }

  .bg-house {
    margin-bottom: 50rem;
  }
}

.bg-overlay {
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgb(251 197 49 / 64%);
  transition: 0.7s all;
}


.no-overlay:hover {
  background: rgb(247, 174 ,41);
  transition: 0.7s all ease-out;
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 80%;
  height: fit-content;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.bg-video {
  background: url(../public/assets/network.jpg) no-repeat;
  background-size: cover;
}

.bg-missi {
  background: url(../public/assets/Large-Mississauga.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  letter-spacing: 0.15em;
}

.leading-large {
  line-height: 2.7rem;
  margin-bottom: 1rem;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  color: white;
  font-size: 0.75rem;
  font-weight: bold;
}

.border-1 {
  border: 1px solid rgb(12, 105, 12);
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
  top: 30px;
  right: 4rem;
}

footer .divider:before,
footer .divider:after {
  background-color: white !important;
}

.duration-500 {
  transition-duration: 500ms;
}

.span-1:hover,
.span-4:hover,
.span-2:hover,
.span-3:hover {
  transition: 1s all ease;
  grid-column: 1 / 3;
}

.span-1:has(+ .span-2:hover),
.span-3:has(+ .span-4:hover) {
  display: none;
}

.span-1:hover + .span-2,
.span-3:hover + .span-4 {
  display: none;
}

.span-1:hover .opacity-0,
.span-3:hover .opacity-0 {
  opacity: 1;
  transition: 1s all ease;
}

.span-2:hover > p,
.span-4:hover > p {
  order: 2;
}

.span-1:hover > p,
.span-4:hover > p,
.span-2:hover > p,
.span-3:hover > p {
  width: 45%;
  margin: 0 auto;
  text-align: center;
}

.span-2:hover > p + p,
.span-4:hover > p + p {
  opacity: 1;
  order: 1;
  transition: 1s all ease;
}

footer .divider {
  margin-top: 0.3rem !important;
  margin-bottom: 2.5rem !important;
}

.bg-white:hover {
  background-color: white !important;
}

.top-12-rem {
  top: 12rem;
}

@media (min-width: 1070px) {
  .absolute-hero {
    right: 0;
    top: 70%;
  }

  .bg-house {
    margin-bottom: 20rem;
    height: 15rem;
  }

  .bg-career {
    margin-bottom: 23rem;
    height: 20rem;
  }

  .top-12-rem {
    top: 7rem !important;
  }

  .lg-absolute {
    position: absolute;
  }

  .absolute {
    right: 2rem;
  }
}

.text-green-700 {
  color: #e39b51 !important;
}

.bg-green-700 {
  background-color:#f6a712 !important;
}

.bg-green-700:hover {
  background-color: #f6a612b0 !important;
}

.hover\:bg-green-700:hover {
  color: white !important;
  background-color: #f7ae29 !important;
}

.hover\:bg-green-700:hover .text-green-700 {
  color: white !important;
}

.active-bg-green-700 {
  color: white !important;
  background-color: #f7ae29 !important;
}

.active-bg-green-700 .text-green-700 {
  color: white !important;
}

.bg-gradiant {
  background: linear-gradient(45deg,#e2ab73,rgb(252 , 211 , 77) 26.43%, rgb(251 , 191 , 36) 57.8%,rgb(245 , 158 , 11)) !important;
}

.hover\:border-green-700:hover {
border-color: rgb(245, 158 ,11) !important;
}

.absolute-header {
  background-image: linear-gradient(
    260deg,
    rgb(252, 211 ,77) -52%,
    rgb(245, 158 ,11, 0.9) 54%
  );
  color: white;
}

.swiper-wrapper {
  align-items: center !important;
}

@media (min-width: 1070px) {
  .cars {
    max-height: 80vh !important;
  }

  #cars {
    overflow-x: hidden;
    overflow-y: auto;
  }

  #houses {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .ml-30 {
    margin-left: 30px;
  }
}

@media (min-width: 778px) and (max-width: 1070px) {
  .top-12-rem {
    top: 9rem;
  }
}

.address {
  background-color: #f1f1f1;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.address .bedrijfsinformatie,
.address .Adres,
.address .openbaar {
  margin: 3rem 0;
}

.bg-blue-400 {
  background-color: rgb(44, 114, 138) !important;
}

.bg-green-900 {
  background-color: #1b4d4a;
}


.email-alert{
  cursor: pointer;
}
